export const STATICPATHS = {
    staticMain: 'static',
    aboutUs: 'about-us',
    contacts: 'contacts',
    partners: 'partners',
    faq: 'faq',
    review: 'review',
    blog: 'blog',
    team: 'team',
    article: 'article',
    vacancies: 'vacancies'
}